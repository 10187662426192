$bg-color: #ffffff;
$text-color: #3a173c;
$hover-color: #cd5e5e;
$light-brown: #c0b9b5;
$all-size: 1.6rem;
$all-weight: 400;

$primary-color: #431a80;
$light-grey: #f2f3f5;
$dark-grey: #edf0f2;
$white-color: #ffffff;
$content-color: #0d1216;
$orange-color: #f37435;

$body-font: Maax Raw, sans-serif;
$transition: 0.3s all linear;
