@import "../../../sass//variables";

.loadingSpinner {
  .spinner-border {
    vertical-align: middle;
    width: 3rem;
    height: 3rem;
  }
  &__info {
    vertical-align: middle;
    margin-left: 1.5rem;
    font-size: 1.8rem;
    color: $content-color;
  }
}
